.entry-page {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  h1 {
    font-weight: 900;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-size: 3.6rem;
    margin-bottom: 4rem;
    margin-top: 3rem;
  }

  .text {
    line-height: 25px;
  }

  .btn {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 2rem 6rem;
  }

  small {
    font-size: 1.3rem;
    color: #323232;
  }

  .boxes {
    display: grid;
    grid-gap: 30px;
    margin-top: 4rem;

    @media (min-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .box {
    border: 1px solid $border-color;
    padding: 2rem;

    @media (min-width: $lg) {
      padding: 5rem;
    }

    h2 {
      text-transform: uppercase;
      font-family: $font-secondary;
      font-weight: 900;
      font-size: 1.8rem;
      margin-top: 2rem;
    }
  }
}