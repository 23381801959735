#box_logowanie {
  .light_gray_bg {
    h2 {
      font-size: 2rem;
      font-weight: 900;
      font-family: $font-secondary;
    }
  }

  .btn {
    float: right;
  }
}

#box_kontakt_form {
  h3 {
    @extend .header;
    padding: 10px;
  }
}

#box_konto {
  div.padding {
    padding-bottom: 3rem;
  }
}

#strony {
  text-align: center;

  a {
    margin: 0 0.2rem;
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
    font-size: 1.8rem;

    &:hover {
      text-decoration: none;
      color: $primary;
    }

    &.active {
      background-color: $primary;
      color: #fff;

      &:hover {
        cursor: default;
        color: #fff;
      }
    }
  }

  span.arrows {
    margin: 0 0.2rem;
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
    font-size: 1.8rem;
    background-color: $primary;
    color: #fff;

    &:hover {
      background-color: mix(#fff, $primary, 10%);
    }
  }
}

#dodawanie_firmy .godziny select,
#dodawanie_firmy .godziny {
  display: inline-block;
  min-width: 120px;
}

#kopiuj_czas {
  margin-left: 1rem;
}

#dodaj_atr {
  h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  @media (min-width: $md) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  label span {
    display: inline-block;
    margin-top: 3px;
  }
}