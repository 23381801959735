#box_produkt #mapa_div div.padding, #mobile_show, .mobile_on, div.hidden {
  display: none;
}

#zalogowany {
  display: inline;
  a {
    color: #7acb62;
  }
}

#zmien_widok {
  float: right;
  img {
    cursor: pointer;
  }
}

#box_produkty #zmien_widok {
  margin-top: 8px;
  margin-left: 10px;
}

.grid ul.lista_firm {
  font-size: 0;
  div {
    &.foto {
      border-right: 0;
      float: none;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
    }
    &.dane {
      margin-left: 0;
    }
  }
  li {
    display: inline-block;
    width: 33%;
    border: 0;
    background-color: transparent;
    vertical-align: top;
    &.promo {
      border: 0;
      box-shadow: unset;
      > div {
        border: 2px solid #fdcf00;
        box-shadow: 0 0 4px #777;
      }
    }
  }
}

ul.lista_firm li.promo {
  border: 2px solid #fdcf00;
  box-shadow: 0 0 4px #777;
}

.grid ul.lista_firm {
  li {
    &.promo h3.nazwa {
      padding-right: 0;
    }
    > div {
      margin: 0 10px;
      border: 1px solid #e6e6e6;
      background-color: #f9f9f9;
      font-size: 12pt;
      position: relative;
    }
  }
  div.foto img {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  li > div.clear {
    border: 0;
  }
  p.align_right {
    margin-bottom: 0;
    position: inherit;
    margin-top: 20px;
    text-align: center;
  }
}

.form input.text_atr {
  background-color: #f9f9f9;
  border: 1px solid silver;
  border-radius: 6px;
  padding: 8px;
}

.mb15 {
  margin-bottom: 15px;
}

#box_news_content, #dodawanie_firmy p, br.clear, div.clear {
  clear: both;
}

#rwd_menu li.home span {
  background-image: url(../../gfx/css_sprites.png);
  display: inline-block;
}

span.hico {
  background-image: url(../../gfx/css_sprites.png);
  display: inline-block;
  margin-right: 2px;
  vertical-align: middle;
}

.ico_l {
  background-position: -10px -84px;
  width: 15px;
  height: 17px;
}

.ico_r {
  background-position: -10px -84px;
  width: 15px;
  height: 17px;
  background-position: -10px -47px;
  width: 13px;
}

#lista_kategorii_2 li.active ul.subkat a, a.link {
  color: #000;
}

#div_movie iframe {
  width: 100%;
  border: 0;
}

img.vtop {
  vertical-align: top;
}

#page_404 {
  text-align: center;
  h1 {
    margin-bottom: 20px;
  }
}

table.godziny {
  margin-bottom: 20px;
}

#news_platny {
  border: 1px solid #333;
  padding: 10px;
  clear: both;
}

ul {
  list-style: none;
  &.sl-options {
    box-shadow: 7px 7px 7px -4px #999;
    z-index: 100;
  }
}

#rwd_menu a:hover, #wplaty a.guzik_red:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

table.godziny {
  width: 100%;
}

#firma_dodatkowe {
  padding: 20px 0;
}

.nowrap {
  white-space: nowrap;
}

dd, dl, h1, h2, h3, h4, h5 {
  margin: 0;
}

ul {
  margin: 0;
  &.lista_firm div.kategorie p {
    margin: 0;
  }
}

.bold {
  font-weight: 700;
}

.rounded {
  border-radius: 5px;
}

.italic {
  font-style: italic;
}

#firma_tel_span a, #top_1_menu img, div.pakiet label, p.oferta_dane b.green {
  margin-right: 10px;
}

#mapa_dojazdu {
  height: 240px;
}

.gray_bg {
  background-color: #313131;
  color: #f5f5f5;
}

.white_bg {
  background-color: #fff;
  color: #242424;
}

.green_bg, .transparent_button:hover {
  background-color: #5abf43;
  color: #fff;
}

.light_gray_bg {
  background-color: #f5f5f5;
  color: #242424;
}

div.clear {
  width: 100%;
  height: 0;
  overflow: hidden;
}

#top_1_menu {
  text-align: right;
  &.logged_in {
    clear: both;
    border-top: 1px solid #666;
  }
}

#drukuj, input.submit {
  cursor: pointer;
}

#jezyki li, #rwd_menu li, #top_1_menu li, .glowna_woj li {
  display: inline-block;
}

#box_news_content ul, #produkt_div ul, div.single_kat ul {
  list-style: disc;
  list-style-position: inside;
}

#produkt_div ol {
  list-style-position: inside;
}

#top_1 p.italic {
  float: left;
  margin-top: 2px;
}

#top_1_menu a {
  color: #fff;
  margin-left: 10px;
  vertical-align: middle;
}

#top_1 {
  padding: 5px 0;
}

#top_1_menu img {
  vertical-align: middle;
}

#rwd_menu {
  float: right;
  font-weight: 700;
  li {
    padding: 14px 18px;
    &.home {
      padding: 6px 18px;
      vertical-align: middle;
      span {
        background-position: -10px -419px;
        background-repeat: no-repeat;
        width: 29px;
        height: 30px;
      }
      &.active span, &:hover span {
        background-position: -10px -452px;
      }
    }
  }
  a {
    color: #000;
    text-transform: uppercase;
  }
}

.transparent_button {
  color: #000;
  text-transform: uppercase;
}

#rwd_menu {
  a:hover {
    color: #7acb62;
  }
  li.dodaj_firme {
    background-color: #fdcf00;
    border-radius: 3px;
    &:hover {
      background-color: #cd9f00;
    }
  }
}

#rwd_menu_mobile li.dodaj_firme:hover {
  background-color: #cd9f00;
}

#rwd_menu li.dodaj_firme:hover a {
  color: #000;
  text-decoration: none;
}

#top_2 {
  padding: 20px 0;
}

#form_szukaj {
  input {
    border: 0;
    padding: 0 15px;
    height: 64px;
    margin-bottom: 10px;
    margin-left: 4px;
    margin-right: 4px;
    font-family: "Source Sans Pro";
    font-size: 13pt;
  }
  select {
    border: 0;
    padding: 0 15px;
    height: 56px;
  }
}

#form_szukaj_submit {
  color: #fff;
  border: 0;
  background-color: #5cc040;
  font-weight: 700;
  cursor: pointer;
  padding: 0 30px;
  box-sizing: content-box;
  &:hover {
    background-color: #2c9010;
  }
}

#logo {
  display: inline-block;
  vertical-align: middle;
}

span.vertical {
  display: inline-block;
  vertical-align: middle;
  height: 49px;
  width: 0;
  overflow: hidden;
}

#logo {
  font-size: 0;
}

#form_szukaj {
  padding-top: 181px;
  h1 {
    margin: 0 0 40px;
    text-shadow: 1px 1px 1px #000;
    color: #fff;
    font-size: 46pt;
  }
  div.clear {
    margin-top: 40px;
  }
  .transparent_button {
    color: #fff;
    border-color: #fff;
    font-size: 17pt;
  }
}

#box_aktualnosci p {
  &.data {
    font-size: 10pt;
  }
  &.excerpt {
    font-size: 10pt;
    overflow: hidden;
    height: 54px;
  }
}

.transparent_button {
  border: 2px solid #6e6e6e;
  color: #6e6e6e;
  cursor: pointer;
  padding: 10px 25px;
  display: inline-block;
  margin-top: 20px;
  font-size: 14pt;
  &:hover {
    border: 2px solid #3a9f23;
    text-decoration: none;
  }
}

#form_szukaj {
  text-align: center;
}

.content {
  h1.h2, h2 {
    text-align: center;
  }
  h1.h2, h2 {
    margin-bottom: 15px;
    font-size: 28pt;
  }
}

.content2 {
  h1.h2, h2 {
    margin-bottom: 15px;
    font-size: 28pt;
  }
}

.content, .content2 {
  padding: 20px 0;
}

#partnerzy_div {
  height: 180px;
  margin: auto;
  width: 1100px;
  li {
    float: left;
  }
}

#box_produkty_szukaj {
  padding: 25px 0;
  text-align: center;
}

#box_newsletter label, #box_produkty_szukaj label {
  font-weight: 700;
  font-size: 18pt;
  margin-right: 10px;
  vertical-align: middle;
}

#box_newsletter input, #box_produkty_szukaj input {
  border: 0;
  border-radius: 3px;
}

#produkty_szukaj {
  background-color: #fff;
  padding: 6px;
  display: inline-block;
  border-radius: 3px;
}

#box_newsletter input.text {
  background-color: transparent;
  color: #737373;
  font-size: 11pt;
  width: 320px;
  vertical-align: middle;
}

#box_produkty_szukaj {
  input.text {
    background-color: transparent;
    color: #737373;
    font-size: 11pt;
    width: 320px;
    vertical-align: middle;
  }
  button.submit {
    background-color: #313131;
    color: #fff;
    padding: 15px;
    border: 0;
    border-radius: 3px;
    span {
      width: 16px;
      background-position: -10px -10px;
      height: 17px;
      margin-left: 15px;
    }
  }
}

div {
  &.latest_single {
    display: block;
    float: left;
    width: 20%;
    margin-bottom: 10px;
    color: #242424;
    div.column {
      border: 1px solid #e6e6e6;
      margin: 0 7px;
      &:hover {
        box-shadow: 0 0 12px #999;
      }
    }
  }
  &.single_news_in:hover {
    box-shadow: 0 0 12px #999;
  }
  &.latest_single a, &.opinia a.link, &.single_news h3 a {
    color: #242424;
  }
}

ul.lista_firm {
  h3 a, p.adres .woj {
    color: #242424;
  }
}

div.latest_single img {
  width: 100%;
}

.cena {
  color: #5abf43;
  font-weight: 700;
}

.producent {
  font-weight: 700;
}

div {
  &.padding {
    padding: 15px;
  }
  &.padding_5 {
    padding: 5px;
  }
  &.latest_single {
    .pb {
      padding-bottom: 6px;
    }
    div {
      &.links {
        border-top: 1px solid #e6e6e6;
        color: #007095;
        font-size: 10pt;
        a {
          color: #007095;
        }
      }
      &.adres {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .miasto {
      color: #5abf43;
      padding-left: 0;
    }
  }
}

#box_produkty_szukaj button.submit span, div.latest_single .miasto span {
  background-image: url(../../gfx/css_sprites.png);
  display: inline-block;
}

span {
  &.ocena, &.ocena_outer {
    background-image: url(../../gfx/css_sprites.png);
    display: inline-block;
  }
}

div.latest_single .miasto span {
  background-position: -70px -10px;
  width: 9px;
  margin-right: 2px;
  overflow: hidden;
  vertical-align: baseline;
  background-repeat: no-repeat;
  height: 11px;
}

.opinia_jeden {
  float: left;
  margin-bottom: 25px;
  width: 33%;
  div.opinia {
    height: 150px;
  }
}

#box_newsletter {
  text-align: center;
  div.wrapper {
    padding: 25px 0;
    background-image: url(../../gfx/zielona_koperta.png);
    background-repeat: no-repeat;
    background-position: 140px center;
  }
}

#newsletter_dolacz {
  background-color: #fff;
  padding: 6px;
  display: inline-block;
  border-radius: 3px;
}

a.green {
  color: #5abf43;
}

#box_newsletter input.submit {
  background-color: #313131;
  color: #fff;
  padding: 15px 26px;
}

#box_mapa {
  background-color: #2f2c2c;
  background-image: url(../../gfx/tlo-za-mapa.jpg);
  background-position: center bottom;
  background-repeat: repeat-x;
  h2 {
    color: #fff;
  }
}

.glowna_woj a {
  color: #fff;
}

#map-poland li {
  text-indent: -99999px;
}

.glowna_woj {
  ul {
    padding-top: 30px;
  }
  li {
    width: 18%;
    vertical-align: top;
  }
}

div {
  &.row {
    display: table;
    width: 100%;
  }
  &.col {
    display: table-cell;
    vertical-align: top;
  }
}

#box_opinie div.col {
  width: 33%;
}

div.center, p.zobacz_wiecej {
  text-align: center;
}

span {
  &.ocena {
    background-repeat: repeat-x;
    height: 19px;
  }
  &.ocena_outer {
    background-repeat: repeat-x;
    height: 19px;
    width: 94px;
    background-position: -10px -312px;
  }
  &.ocena {
    background-position: -10px -291px;
    text-indent: -999999px;
  }
  &.ocena_1 {
    width: 20%;
  }
  &.ocena_2 {
    width: 40%;
  }
  &.ocena_3 {
    width: 60%;
  }
  &.ocena_4 {
    width: 80%;
  }
  &.ocena_5 {
    width: 100%;
  }
}

div {
  &.opinia {
    border: 1px solid #e6e6e6;
    margin: 0 15px;
    background-image: url(../../gfx/opinia.png);
    background-position: top right;
    background-repeat: no-repeat;
    img.thumb {
      float: left;
      margin-right: 10px;
    }
    h2 {
      text-align: left;
      margin-bottom: 5px;
      font-size: 13pt;
    }
  }
  &.pb0 {
    padding-bottom: 0;
  }
  &.single_kat {
    width: 25%;
    float: left;
    margin-bottom: 30px;
  }
  &.single_kat_in {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin: 0 15px;
    padding: 10px;
    background-image: url(../../gfx/kategoria.png);
    background-position: left bottom;
    background-repeat: no-repeat;
  }
}

p.zobacz_wiecej {
  border: 1px solid #cecece;
  text-transform: uppercase;
  margin-top: 10px;
}

a.data, div.single_kat span.arrows {
  color: #5fc344;
}

#ajax_opcje span.arrows {
  color: red;
}

p.zobacz_wiecej a {
  color: #505050;
  display: block;
  padding: 10px 0;
  &:hover {
    background-color: #5fc344;
    cursor: pointer;
    border-color: #5fc344;
    color: #fff;
    text-decoration: none;
  }
}

div {
  &.single_kat {
    a {
      color: #505050;
    }
    li {
      margin-top: 5px;
      font-size: 11pt;
    }
    h3 {
      height: 55px;
      overflow: hidden;
    }
  }
  &.single_kat_ul {
    height: 160px;
    overflow: hidden;
  }
  &.single_news {
    width: 25%;
    float: left;
  }
  &.single_news_in {
    margin: 0 10px;
    background-color: #f8f8f8;
  }
  &.single_news h3 {
    margin: 8px 0;
    height: 74px;
    overflow: hidden;
  }
}

#dodaj_produkt #dodawanie_firmy textarea, #schowek, #sidebar .white_form_box input.text, div.single_news img.thumb {
  width: 100%;
}

#box_statystyki {
  li {
    width: 33%;
    text-align: center;
    display: inline-block;
  }
  ul {
    padding: 30px 0;
  }
  strong {
    display: block;
    font-size: 22pt;
  }
}

#box_haslo h2, #box_logowanie h2, #box_statystyki img, .glowna_woj li {
  margin-bottom: 15px;
}

a.rss {
  background-image: url(../../gfx/css_sprites.png);
}

a.rss {
  position: absolute;
  top: 0;
  right: 0;
  background-position: -10px -351px;
  width: 48px;
  height: 48px;
  display: block;
}

span {
  &.stat {
    background-image: url(../../gfx/css_sprites.png);
    display: inline-block;
    margin-bottom: 5px;
  }
  &.stat_1 {
    width: 112px;
    height: 93px;
    background-position: -10px -779px;
  }
  &.stat_2 {
    width: 74px;
    height: 93px;
    background-position: -10px -892px;
  }
  &.stat_3 {
    width: 98px;
    height: 94px;
    background-position: -10px -1005px;
  }
}

.relative {
  position: relative;
}

#schowek {
  position: fixed;
  bottom: 0;
  text-align: center;
  span.info {
    padding: 10px;
    display: none;
    background-color: rgba(92, 182, 210, 0.8);
  }
}

#schowek_tlo {
  padding: 10px;
  display: none;
  background-color: rgba(92, 182, 210, 0.8);
}

#schowek span {
  &.info {
    display: block;
    margin: auto;
    padding: 5px;
    color: #fff;
    line-height: 35px;
    font-size: 18px;
    width: 250px;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
  }
  &.arrow_s {
    background-image: url(../../gfx/css_sprites.png);
    display: block;
    background-position: -10px -501px;
    width: 47px;
    height: 35px;
    float: left;
    &.act {
      background-position: -10px -535px;
    }
  }
}

#schowek_zawartosc {
  width: 900px;
  margin: auto;
  div.single {
    float: left;
    margin: 10px;
    padding: 6px;
  }
  span.usun {
    display: block;
    margin-top: 10px;
    color: #fff;
    background-image: url(../../gfx/schowek_x.png);
    background-repeat: no-repeat;
    padding-left: 22px;
    height: 22px;
  }
}

img.s_foto {
  margin-right: 5px;
}

#schowek_zawartosc a {
  color: #000;
  font-weight: 700;
}

.cursor {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

div {
  &.border, &.single_news_in {
    border: 1px solid #e6e6e6;
  }
}

ul.lista_firm li {
  border: 1px solid #e6e6e6;
}

#box_haslo div.border, #box_logowanie div.border {
  margin: 0 10px;
  padding: 10px;
}

p {
  &.info_err {
    background-color: #ff8585;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  &.info_ok {
    background-color: #393;
    padding: 10px;
  }
}

.form input.text {
  height: 47px;
  line-height: 47px;
  text-indent: 10px;
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  box-sizing: border-box;
}

.dane_firmy p.txt {
  margin-bottom: 10px;
}

.form {
  input {
    &.text, &[type=file] {
      margin-bottom: 10px;
    }
  }
  textarea {
    margin-bottom: 10px;
  }
}

p.info_ok {
  margin-bottom: 10px;
}

.form {
  textarea {
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    box-sizing: border-box;
  }
  input {
    border-radius: 3px;
  }
}

p.info_ok {
  border-radius: 3px;
}

.mt {
  margin-top: 20px;
}

#sidebar .widget, .mb {
  margin-bottom: 20px;
}

#box_logowanie a {
  &.green {
    color: #5fc344;
    text-decoration: underline;
  }
  &.password {
    color: #242424;
    text-decoration: underline;
    line-height: 32px;
  }
}

#box_haslo input.submit, #box_konto input.submit, #box_logowanie input.submit {
  border: 0;
  float: right;
  background-color: #5fc344;
  color: #fff;
  padding: 8px 24px;
}

#fb_login {
  background-color: #3f5d9d;
  color: #fff;
  padding: 3px 6px;
  display: inline-block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.width_35 {
  width: 35%;
}

p.info_ok, ul.lista_firm div.kategorie a.zobacz_produkty {
  color: #fff;
}

#lista_kategorii_2 {
  ul {
    list-style: disc;
    list-style-position: inside;
    color: #646464;
  }
  li {
    margin-bottom: 8px;
  }
}

ul.lista_firm {
  h3, p {
    margin-bottom: 8px;
  }
}

#lista_kategorii_2 {
  a {
    color: #646464;
  }
  li.active {
    a {
      color: #5fc243;
      font-weight: 700;
    }
    ul.subkat a.active {
      color: #5fc243;
    }
  }
}

#lista_kategorii_2 li {
  ul.subkat {
    display: none;
    margin-top: 8px;
  }
  &.active ul.subkat {
    display: block;
  }
}

span.clear {
  display: block;
}

#lista_kategorii_2 ul.subkat {
  list-style: circle;
  padding-left: 20px;
}

#sidebar {
  width: 305px;
}

.white_form_box_outer {
  width: 100%;
  display: inline-block;
}

.white_form_box {
  background-color: #fff;
  border-radius: 3px;
  display: block;
  padding: 10px 6px;
}

span.clear {
  clear: both;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.white_form_box input {
  border: 0;
  border-radius: 3px;
  &.text {
    background-color: transparent;
    color: #737373;
    width: 68%;
    line-height: 25px;
  }
  &.submit {
    background-color: #5fc344;
    float: right;
    color: #fff;
    padding: 4px 12px;
  }
}

#box_lista_firm {
  margin-right: 20px;
}

ul.lista_firm li {
  background-color: #f9f9f9;
  margin-bottom: 24px;
  position: relative;
}

.lista_firm {
  margin-top: 20px;
}

ul.lista_firm {
  li.promo h3.nazwa {
    padding-right: 130px;
  }
  div {
    &.foto {
      float: left;
      border-right: 1px solid #e6e6e6;
    }
    &.dane {
      margin-left: 250px;
      color: #242424;
      background-color: #fff;
    }
  }
}

#firma_div h2.naglowek, ul.lista_firm h3 {
  font-size: 16pt;
}

p.tel {
  background-position: left 3px;
  background-repeat: no-repeat;
}

ul.lista_firm p.adres {
  background-position: left 3px;
  background-repeat: no-repeat;
  color: #5fc142;
  text-transform: uppercase;
  font-weight: 700;
  background-image: url(../../gfx/marker.png);
  padding-left: 16px;
}

#box_firmy_szukaj ul.miasta li.active a {
  color: #5fc142;
}

ul.lista_firm {
  p.adres a {
    color: #5fc142;
  }
  div.kategorie {
    background-color: #f9f9f9;
    border-top: 1px solid #e6e6e6;
    bottom: 0;
    left: 226px;
    right: 0;
    color: #323232;
    padding: 11px;
    a {
      color: #007095;
    }
  }
}

p.tel {
  background-image: url(../../gfx/telefon.png);
  padding-left: 21px;
}

ul.lista_firm p.opis_krotki {
  font-size: 11pt;
  max-height: 40px;
  overflow: hidden;
}

#footer {
  padding: 50px 0;
  color: #9b9b9b;
  img {
    vertical-align: middle;
  }
  div.linia {
    border-top: 1px solid #5b5b5b;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 1px;
    overflow: hidden;
  }
}

#box_firmy_szukaj ul.miasta a, #footer a, .dark_gray_bg a {
  color: #fff;
}

#footer_bottom {
  line-height: 40px;
}

#box_stopka_linki {
  table-layout: fixed;
  text-align: center;
  a {
    font-weight: 700;
  }
}

#box_kontakt_form {
  .text {
    background-color: #f9f9f9;
    border: 1px solid #efefef;
  }
  textarea {
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    box-sizing: border-box;
    line-height: 47px;
    margin-bottom: 10px;
    text-indent: 10px;
    width: 100%;
    height: 100px;
  }
  div.col {
    padding: 0 10px;
  }
}

div.align_right {
  text-align: right;
  input.text {
    width: auto;
  }
}

#box_kontakt_form input.submit {
  background-color: #5fc344;
  border: 0;
  color: #fff;
  padding: 14px 24px;
  margin-left: 10px;
}

#box_miasta a, #box_tagi a {
  color: #242424;
  margin-right: 20px;
}

#top_3 {
  background-image: url(../../gfx/top1.jpg);
  background-position: center bottom;
  background-repeat: repeat-x;
  position: relative;
  background-color: #fff;
  &.top2 {
    background-image: url(/img/top2.jpg);
  }
  &.top1 {
    background-image: url(/img/top1.jpg);
  }
}

.home {
  #top_3 {
    height: 534px;
  }
  #box_tagi {
    border-bottom: 1px solid #ddd;
  }
  #box_miasta {
    border-top: 1px solid #fff;
  }
}

.body_akcja #top_3 {
  height: 232px;
  background-image: url(../../gfx/top-maly.jpg);
}

.home #top_3.no_search {
  height: 232px;
  background-position: center top;
}

.dane_firmy h3, div.widget h3 {
  margin-bottom: 15px;
}

#box_firmy_szukaj div.line {
  border-bottom: 1px solid #141414;
  border-top: 1px solid #464646;
  height: 0;
  overflow: hidden;
  margin: 15px 0;
}

#produkty_szukaj_box div.line {
  border-bottom: 1px solid #141414;
  border-top: 1px solid #464646;
  height: 0;
  overflow: hidden;
  margin: 15px 0;
  margin: 10px 15px 0;
}

#box_firmy_szukaj ul.miasta {
  list-style: disc;
  list-style-position: inside;
  color: #949494;
  li {
    float: left;
    width: 49%;
    margin-bottom: 10px;
  }
}

#box_lista_kategorii {
  background-image: url(../../gfx/kategoria.png);
  background-position: left bottom;
  background-repeat: no-repeat;
}

div {
  &.widget {
    border-radius: 3px;
  }
  &.promo {
    color: #000;
    text-transform: uppercase;
    background-color: #fdcf00;
    position: absolute;
    top: 25px;
    right: 0;
    padding: 4px 10px;
    font-weight: 700;
  }
  &.sl-container {
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    text-align: left;
    text-indent: 15px;
    font-size: 13pt;
    vertical-align: top;
  }
}

#box_wszystkie_opinie .w_linii a, #plugin_aktualnosci_lista h3 a, div.sl-container a {
  color: #000;
}

.sl-placeholder-container {
  height: 64px;
  line-height: 64px;
  overflow: hidden;
}

.sl-button {
  font-size: 28px;
  line-height: 86px;
}

.sl-options {
  background-color: #fff;
  padding: 10px;
}

.foto_firmy {
  width: 225px;
  ul {
    padding: 10px;
    color: #242424;
  }
}

.dane_firmy p.adres a, .foto_firmy ul a, span.ocena_int a {
  color: #242424;
}

.foto_firmy li {
  border-bottom: 1px solid #efefef;
}

.dane_firmy {
  padding: 20px;
  position: relative;
  p {
    &.adres, &.www {
      font-weight: 700;
      color: #242424;
    }
    &.kategorie a, &.www a {
      color: #5fc243;
      font-weight: 700;
    }
  }
}

#kod_qr {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

#firma_tel_button, #firma_tel_span {
  margin-bottom: 6px;
  display: inline-block;
  font-weight: 700;
}

#firma_tel_button {
  background-color: #5fc243;
  color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 0 silver;
  cursor: pointer;
  padding: 4px 12px;
}

#firma_tel_span {
  padding: 4px 0;
  font-size: 14pt;
}

p.center {
  text-align: center;
}

#firma_div {
  margin-right: 20px;
  border-radius: 3px;
}

span.ocena_int {
  font-size: 10pt;
  margin-left: 8px;
  vertical-align: top;
}

#opinie_pl {
  position: relative;
}

#dodaj_opinie {
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 12px;
  background-color: #5fc243;
  box-shadow: 1px 1px 0 silver;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  border-radius: 3px;
}

#opinie_pl div.linia {
  border-top: 1px solid #dedede;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 1px;
  overflow: hidden;
  clear: both;
}

#div_nazwa {
  position: relative;
  margin-bottom: 15px;
}



#add-favourites-item img, #remove-favourites-item img {
  margin-left: 10px;
  vertical-align: middle;
}

#add-favourites-item, #remove-favourites-item {
  color: #fff;
  padding: 6px 12px;
  box-shadow: 1px 1px 0 silver;
  font-weight: 700;
}

#add-favourites-item {
  cursor: pointer;
  display: inline-block;
  background-color: #5fc243;
}

#remove-favourites-item {
  background-color: #5f1111;
}

div.opinia_oceny {
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
  padding: 8px;
  display: inline-block;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#mapa_dolna_linia label {
  font-size: 11pt;
}

div {
  &.opinia div.txt {
    font-size: 11pt;
  }
  &.opinia_oceny {
    tr.strong td {
      font-weight: 700;
      padding-bottom: 10px;
    }
    td.right {
      text-align: right;
      padding-right: 10px;
    }
  }
}

span.gwiazdki {
  background-image: url(../../gfx/ocena.png);
  background-position: left bottom;
  background-repeat: repeat-x;
  display: inline-block;
  height: 19px;
  width: 95px;
}

p.opinia_tekst {
  padding-top: 20px;
}

.dark_gray_bg {
  color: #fff;
  background-color: #323232;
}


div {
  &.mini_foto {
    display: inline-block;
    cursor: pointer;
  }
  &.miniatury {
    margin-top: 10px;
  }
}

#box_firma {
  h1 {
    margin-top: 50px;
  }
  #sidebar {
    width: 400px;
    h2.naglowek {
      font-size: 16pt;
      margin: 0;
    }
  }
}

#mapa_gorna_linia {
  font-weight: 700;
  color: #5fc344;
  font-size: 12pt;
  a {
    font-weight: 700;
    color: #5fc344;
    font-size: 12pt;
  }
}

#lokalizacja {
  cursor: pointer;
}

#mapa_gorna_linia {
  margin-bottom: 10px;
}

.dane_prod {
  p, strong {
    margin-bottom: 10px;
  }
}

#dodaj_news img, #mapa_dolna_linia div.col {
  vertical-align: middle;
}

#adres_go, span.szare_pole {
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
}

#adres_go {
  color: #fff;
  background-image: url(../../gfx/zoom.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 37px;
  height: 34px;
  box-shadow: 1px 1px 0 #bcbcbc;
  background-color: #5fc344;
}

span.szare_pole {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  padding: 6px;
  input {
    border: 0;
    background-color: transparent;
    vertical-align: middle;
    font-size: 12pt;
    width: 160px;
  }
}

#div_list {
  margin-top: 10px;
}

#firma_opcje {
  margin-top: 10px;
  li {
    display: inline-block;
    margin-right: 15px;
  }
  a {
    color: #242424;
  }
}

#firma_info div.linia {
  border-top: 1px solid #dedede;
  clear: both;
  height: 1px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
}

#firma_div {
  #box_opinie {
    border-top: 1px solid #e6e6e6;
  }
  img.foto {
    border-radius: 3px;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
}

.align_center {
  text-align: center;
}

#wplaty {
  margin: auto;
}

.zobacz_produkty {
  background-color: #5cc040;
  border: 0;
  color: #fff;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 5px;
  text-shadow: 0 0 2px #666;
}

#box_produkt {
  #sidebar {
    width: 400px;
  }
  #mapa_div {
    margin: 0;
    border: 0;
    width: 100%;
    height: 200px;
  }
}

.guzik_green, .guzik_lightgray, .guzik_orange, .guzik_red {
  background-color: #5fc243;
  color: #fff;
  font-weight: 700;
  padding: 8px 12px;
  border-radius: 3px;
  box-shadow: 1px 1px 0 silver;
  margin-bottom: 6px;
  display: inline-block;
  cursor: pointer;
  border: 0;
}

.guzik_lightgray, .guzik_orange, .guzik_red {
  background-color: #ff7022;
}

.guzik_lightgray, .guzik_red {
  background-color: #ff5022;
}

.guzik_lightgray {
  background-color: #7e7e7e;
}

#produkt_urle .guzik_orange {
  display: block;
}

#aktualnosci_kategoria li.active a {
  color: #5fc243;
}

.dane_prod {
  p.www a {
    color: #5fc243;
  }
  strong {
    display: block;
  }
}

#box_produkt {
  .dostepnosc, .polecany {
    font-size: 9pt;
  }
  h1 {
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;
  }
}

#produkt_galeria {
  float: left;
}

#produkt_div, #produkt_galeria {
  margin-right: 20px;
}

#box_produkt {
  .cena {
    font-size: 18pt;
    font-weight: 400;
  }
  #polec_boks {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-top: 20px;
    clear: both;
  }
}

.alert-cloud {
  float: right;
  width: 40%;
}

#dodawanie_firmy {
  label {
    float: left;
    clear: both;
    margin-bottom: 5px;
    &.nf {
      float: none;
    }
  }
  span.arrows {
    color: red;
  }
  input.text, select, textarea {
    float: left;
    width: 40%;
    clear: left;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border: 1px solid silver;
    border-radius: 6px;
    padding: 8px;
  }
  .mce-tinymce:focus, input.text:focus, select:focus, textarea:focus {
    box-shadow: 0 0 3px #2b4fad;
  }
  .godziny select { 
    width: auto; 
	 clear: none; 
	 float: none; 
  }	
}
 

.foto_wgrane input {
  &:focus {
    box-shadow: 0 0 3px #2b4fad;
  }
  background-color: #f9f9f9;
  border: 1px solid silver;
  border-radius: 6px;
  padding: 8px;
}

#dodawanie_firmy input.short {
  width: 120px;
  margin-right: 10px;
}

#firma_div span.tel {
  background-position: left 4px;
  background-image: url(../../gfx/telefon.png);
  padding-left: 20px;
  background-repeat: no-repeat;
}

#dodawanie_firmy {
  input.miasto {
    width: 250px;
    clear: none;
  }
  div.kolumna {
    width: 50%;
    float: left;
    clear: left;
  }
}

#map_canvas {
  width: 50%;
  float: left;
  clear: left;
}

#dodawanie_firmy h2.naglowek2 {
  clear: both;
  font-size: 16pt;
  border-top: 1px solid #efefef;
  margin-top: 15px;
  padding-top: 10px;
}

#map_canvas {
  width: 40%;
  height: 300px;
}

#dodawanie_firmy .mce-tinymce, div.clear_left {
  clear: left;
}

#div_tagi {
  clear: both;
}

#edytor_jezyki {
  clear: both;
  li {
    display: inline;
    cursor: pointer;
    border: 1px solid transparent;
    img {
      border: 1px solid transparent;
    }
    &.active img {
      border: 1px solid #0f0;
    }
  }
}

#div_tagi span.delete_tag {
  background-image: url(../../gfx/delete.png);
  border: 0;
  display: inline-block;
  float: none;
  height: 16px;
  line-height: 15px;
  margin: 0 0 0 4px;
  padding: 0;
  vertical-align: text-top;
  width: 16px;
}

#dodaj_atr {
  input {
    clear: left;
    margin: 4px 9px 0 0;
    width: auto;
  }
  label {
    clear: right;
    float: none;
  }
  li {
    display: block;
  }
}

.kup_teraz {
  background-image: url(../../gfx/kupteraz.png);
  width: 152px;
  height: 51px;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  line-height: 51px;
  text-align: left;
  text-indent: 26px;
}

#popup {
  background-image: url(../../gfx/popup_bg.png);
  background-repeat: repeat;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

#popup_in {
  width: 400px;
  margin: 10% auto auto;
}

#popup_nie, #popup_tak {
  cursor: pointer;
}

#box_lista_zlecen .w_linii a, #popup_tak {
  float: right;
}

#popup_in p {
  font-size: 18px;
  margin-bottom: 20px;
}

#popup_alert {
  color: #000;
  font-weight: 700;
}

.guzik_gray {
  color: #000;
  font-weight: 700;
  background-color: #fff;
  cursor: pointer;
  padding: 4px 10px;
  text-shadow: 1px 1px 1px #e7e7e7;
  text-transform: uppercase;
  border: 1px solid #000;
  border-radius: 8px;
  display: inline-block;
}

#popup_alert {
  border: 8px solid red;
  border-radius: 80px;
  display: block;
  font-size: 34px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  text-align: center;
  width: 100px;
}

#layer {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999;
  display: none;
  padding: 20px;
}

#layer_in {
  padding: 15px;
  width: 650px;
  margin: auto;
  background-color: #fff;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
}

#foto_nav_big {
  display: none;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  span {
    -moz-border-radius: 5px 5px 5px 5px;
    background-image: url(../../gfx/fancybox.png);
    display: block;
    height: 30px;
    width: 30px;
  }
}

#nav_prev {
  position: absolute;
  background-position: -40px -30px;
  top: 48%;
  left: 30px;
}

#big_foto {
  cursor: pointer;
}

#close_layer {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background-image: url(../../gfx/fancybox.png);
  display: block;
  height: 30px;
  width: 30px;
  background-position: -40px 0;
}

#nav_next {
  position: absolute;
  background-position: -40px -60px;
  top: 48%;
  right: 30px;
}

#ajax_opcje {
  display: none;
  li {
    display: none;
  }
  textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

#layer_in textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

#ajax_opcje textarea, #dodaj input.text {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 8px;
}

#layer_in {
  input.text, textarea {
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    border-radius: 6px;
    padding: 8px;
  }
}

#news_kom textarea, p.info {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 8px;
}

#news_kom textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#box_wszystkie_opinie div.linia {
  border-top: 1px solid #dedede;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 1px;
  overflow: hidden;
  clear: both;
  &.last {
    border-top: 0;
  }
}

#box_lista_firm .w_linii h1, #box_wszystkie_opinie .w_linii h1 {
  display: inline-block;
  font-size: 20pt;
}

#plugin_aktualnosci_lista .w_linii {
  h1.h2, h2 {
    display: inline-block;
    font-size: 20pt;
  }
}

#box_wszystkie_opinie {
  .w_linii, div.border {
    margin-bottom: 10px;
  }
  .w_linii span {
    font-weight: 700;
    font-size: 20pt;
  }
}

#box_lista_zlecen .w_linii {
  height: 46px;
  margin-bottom: 10px;
  h1 {
    line-height: 46px;
  }
}

#plugin_aktualnosci_lista .w_linii a {
  float: right;
  color: #fff;
}

#box_zlecenie .w_linii a, #dodaj_news {
  float: right;
}

#box_zlecenie .w_linii, #fb_login, #plugin_aktualnosci_lista h3 {
  margin-bottom: 10px;
}

#box_lista_zlecen .w_linii h1 {
  display: inline-block;
  font-size: 20pt;
}

#box_zlecenie .w_linii {
  h1 {
    display: inline-block;
    font-size: 20pt;
  }
  .guzik_lightgray {
    padding: 8px 20px;
  }
  .guzik_red {
    padding: 8px 20px;
    margin-right: 10px;
  }
}

#dodaj {
  input.text {
    width: 300px;
    float: left;
    clear: left;
  }
  label {
    float: left;
    margin-bottom: 5px;
    clear: both;
  }
  .arrows {
    color: red;
  }
}

#rodzaj_konta {
  clear: both;
  margin-bottom: 15px;
  label {
    float: none;
  }
}

.news_img {
  float: left;
  margin-right: 20px;
}

#box_news_title {
  margin-bottom: 20px;
  h1 {
    display: inline-block;
  }
}

#box_nowa_opinia .linia h1, #nowa_opinia label {
  display: inline-block;
}

#box_news_title .guzik_lightgray {
  float: right;
}

p.info {
  background-color: #eefaec;
  padding: 10px;
  border-radius: 5px;
}

#komentarze .autor, p.info a {
  color: #5ec343;
}

#komentarze, span.budzet {
  margin-top: 10px;
}

#komentarze {
  div.komentarz {
    border: 1px solid #efefef;
    padding: 20px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    background-image: url(../../gfx/komentarz.png);
    background-position: top right;
    background-repeat: no-repeat;
  }
  p {
    &.dodano {
      font-size: 10pt;
    }
    &.tekst {
      font-size: 12pt;
    }
  }
}

#box_komentarze p.bold {
  margin-bottom: 15px;
}

#plugin_aktualnosci_lista {
  margin-right: 20px;
  a.more {
    color: #5ec343;
    font-weight: 700;
  }
  img.thumb {
    float: left;
    width: 225px;
    margin-right: 20px;
  }
  li {
    box-shadow: 0 0 3px #999;
    margin-bottom: 20px;
    position: relative;
  }
}

#aktualnosci_kategoria h2, #aktualnosci_popularne h2, #box_zlecenia h2.naglowek {
  font-size: 14pt;
}

#aktualnosci_kategoria {
  ul {
    list-style: disc;
    color: #949494;
    list-style-position: inside;
  }
  a {
    color: #fff;
  }
}

#plugin_aktualnosci_lista div.kategoria {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 225px;
  background-color: #f9f9f9;
  border-top: 1px solid #e6e6e6;
  padding: 8px 8px 8px 20px;
  a {
    color: #007296;
  }
}

#nowa_opinia {
  label {
    vertical-align: top;
    text-align: right;
    margin-right: 10px;
  }
  input.text, textarea {
    width: 60%;
  }
  input.text.short {
    width: 150px;
  }
}

.opinia_prawa label {
  padding-top: 13px;
}

.col.opinia_prawa {
  text-align: right;
}

.body_404 #top_3 {
  height: 0;
}

#nowa_opinia .arrows, .form_oferta span.arrows {
  color: red;
}

#nowa_opinia_dodaj {
  margin-left: 20px;
  padding: 13px 20px;
}

#box_nowa_opinia .linia {
  margin-bottom: 10px;
  span {
    font-size: 23pt;
    font-weight: 700;
  }
  a {
    float: right;
  }
}

div {
  &.ocena_ogolem.polowa2 {
    margin-bottom: 15px;
    font-weight: 700;
  }
  &.polowa2 {
    margin-bottom: 8px;
  }
}

#box_nowa_opinia {
  span {
    &.gwiazdka {
      display: inline-block;
      background-image: url(../../gfx/ocena.png);
      width: 19px;
      height: 19px;
      cursor: pointer;
      background-position: bottom;
      margin-right: 3px;
    }
    &.gwiazdka_on {
      background-position: top;
    }
  }
  div.polowa2 label {
    width: 200px;
  }
}

#aktualnosci_popularne a, p.oferta_txt {
  color: #000;
}

#aktualnosci_popularne li, #pakiety p.intro {
  margin-bottom: 20px;
}

div.pakiet {
  margin-bottom: 20px;
  box-shadow: 0 0 3px #333;
  padding: 10px; 	
  form {
    display: block;
    padding: 10px;
    background-color: #f9f9f9;
    clear: both;
  }
  .guzik_green {
    float: right;
    margin: 0;
  }
  img.typ {
    margin: 30px;
    vertical-align: top;
    float: left;
  }
  label {
    display: inline-block;
	 margin-top: 5px; 	
  }	
  ul {
	 list-style: disc;
	 list-style-position: inside;
  }
  select {
    margin-top: 6px; 
	 margin-bottom: 6px; 	
  }		
  span.nazwa {
    font-size: 24pt;
    font-weight: 700;
    margin: 10px 0 20px 0;
    display: inline-block;
  }
}

#promo_bottom p {
  margin: 15px 0;
}

#moje_firmy {
  div {
    &.half {
      display: table-cell;
      width: 50%;
      height: 100%;
      position: relative;
      vertical-align: top;
      &.half_long {
        width: auto;
      }
      &.half_short {
        width: 200px;
        text-align: center;
        p {
          padding-top: 30%;
        }
      }
    }
    &.dane {
      display: table;
      height: 225px;
      margin: 0;
      width: 100%;
    }
    &.foto {
      float: none;
      display: table-cell;
      width: 225px;
    }
  }
  img.thumb {
    display: block;
  }
  div.oplac {
    border-left: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    height: 100%;
  }
}

.border_top {
  border-top: 1px solid #e6e6e6;
}

div {
  &.pakiet form, &.zlecenie_bottom div.col {
    border-top: 1px solid #e6e6e6;
  }
}

#moje_firmy {
  div.oplac > div {
    height: 50%;
  }
  .o_head_1, .o_head_2 {
    display: block;
    margin-bottom: 6px;
    font-weight: 700;
    color: #000;
  }
  .button img {
    vertical-align: middle;
    margin-left: 20px;
  }
}

a.link_red {
  color: #a93a40;
}

#moje_firmy div.stat {
  bottom: 0;
  font-size: 10pt;
  left: 0;
  padding: 10px;
  position: absolute;
  right: 0;
}

a.link_green {
  color: #7aa86a;
}

#moje_firmy .button {
  display: inline-block;
  padding: 7px 14px;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
}

#aktualnosci_popularne li.last, #moje_firmy p {
  margin-bottom: 0;
}

.button {
  &.blue {
    background-color: #7bb4c8;
    box-shadow: 1px 1px 0 #afc6ce;
  }
  &.red {
    background-color: #cc2c2c;
    box-shadow: 1px 1px 0 #dcb4b4;
  }
  &.green {
    background-color: #5fc243;
    box-shadow: 1px 1px 0 #abd1a0;
  }
}

div.opinia_full {
  background-position: left bottom;
  background-repeat: no-repeat;
  background-image: url(../../gfx/opinia.png);
}

#box_lista_zlecen {
  margin-right: 20px;
}

div {
  &.zlecenie {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 20px;
    h2 {
      font-size: 20pt;
      color: #000;
    }
  }
  &.zlecenie_bottom {
    background-color: #f9f9f9;
    border-collapse: collapse;
    div.col {
      border-right: 1px solid #e6e6e6;
      padding: 14px;
      font-weight: 700;
      text-align: center;
    }
  }
}

span {
  &.budzet {
    float: left;
  }
  &.red {
    color: #900;
  }
}

div.zlecenie_bottom {
  div.col.last {
    border-right: 0;
    padding: 0;
    background-color: #5fc444;
    a {
      color: #fff;
      padding: 14px;
      display: block;
    }
  }
  span.green {
    margin-left: 10px;
  }
}

span.green {
  color: #5fc444;
}

#wojewodztwa_rwd {
  margin-bottom: 20px;
}

#filtruj_zlecenia ul.miasta a, #wojewodztwa_rwd a {
  color: #fff;
  display: list-item;
}

#filtruj_zlecenia ul.miasta li, #wojewodztwa_rwd li {
  display: inline-block;
  font-size: 12pt;
  list-style: inside none disc;
  overflow: hidden;
  white-space: nowrap;
  width: 49%;
}

.col_1_3 {
  width: 33%;
}

.col_2_3 {
  width: 66%;
}

#oferty .nazwa img, .guzik .ikona {
  vertical-align: middle;
  margin-left: 15px;
}

#zlecenie_main {
  margin-right: 20px;
}

#zlecenie_info p, .form_oferta label, span.sort {
  display: inline-block;
  width: 49%;
}

#zlecenie_opis {
  margin-bottom: 20px;
  margin-top: 10px;
}

.form_oferta {
  input.text {
    text-indent: 10px;
    width: 60px;
    margin-right: 6px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
  select, textarea {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
  input.text, select {
    margin-bottom: 15px;
    padding: 8px;
  }
  label {
    margin-right: 20px;
    text-align: right;
    width: 60px;
  }
  textarea {
    width: 90%;
    padding: 5px;
    height: 135px;
  }
  .guzik_green {
    padding: 8px 20px;
  }
}

div.oferta {
  padding: 10px 0;
  border-bottom: 1px solid #e9e9e9;
  &.last {
    border-bottom: 0;
  }
}

#oferty_lista {
  border-top: 1px solid #e9e9e9;
  padding: 15px 0 15px 15px;
  .cena {
    color: #111;
  }
  .border_left {
    border-left: 1px solid #e9e9e9;
    width: 140px;
  }
}

#zlecenie_opis h2 {
  font-size: 20pt;
}

#oferty .nazwa {
  margin-bottom: 10px;
  display: inline-block;
}

p.oferta_txt span.data {
  font-weight: 400;
  color: #737373;
}

#dodawanie_firmy label.error, #textarea_info {
  color: red;
}

#box_zlecenie_dodane a.black {
  font-weight: 700;
  color: #000;
}

.margin_top_20 {
  margin-top: 20px;
}

#zleceniodawca span.hint {
  text-decoration: underline;
  color: #588281;
}

p.oferta_dane {
  margin-top: 15px;
}

.green {
  color: #62c342;
}

.widget .guzik_green:hover {
  background-color: #2f9213;
}

div {
  &.img_oferta {
    width: 96px;
  }
  &.green_border {
    border: 1px solid #5fc243;
    padding: 10px;
  }
}

#zleceniodawca li {
  background-image: url(../../gfx/zlecenie_ikony.png);
  background-repeat: no-repeat;
  height: 30px;
  line-height: 22px;
  padding-left: 30px;
  &.tel {
    background-position: left -34px;
  }
}

#zlecenie_zamkniete, p.intro {
  margin-bottom: 20px;
}

#sort_cena {
  float: right;
  margin-right: 20px;
}

#sort_data {
  margin-left: 15px;
}

#box_done a {
  color: #090;
}

span.sort {
  background-image: url(../../gfx/sort.png);
  width: 11px;
  height: 24px;
  &.active {
    background-position: right top;
  }
}

#dodawanie_firmy {
  input.text.error, select.error, textarea.error {
    border: 1px solid red;
  }
}

p.intro {
  color: #9b9b9b;
  text-align: center;
}

#box_miasta a.green, #box_tagi a.green {
  color: #5abf43;
}

#box_miasta, #box_tagi {
  padding-bottom: 40px;
}

#footer span.gray {
  color: #9b9b9b;
  margin-right: 5px;
}

#footer_social a {
  margin: 0 10px;
}

label.error {
  color: red;
  margin-right: 10px;
}

#box_opinie_wszystkie {
  div.opinia_jeden {
    float: none;
    width: 100%;
  }
  .opinia_jeden div.opinia {
    height: auto;
  }
}

#newsletter_txt {
  margin-bottom: 10px;
  font-weight: 700;
}

#box_mapa_miast a {
  color: #5abf43;
}

#mapa_miast {
  > div.woj {
    display: inline-block;
    width: 33%;
    vertical-align: top;
  }
  div.woj_in {
    margin: 6px;
    border: 1px solid #ccc;
    padding: 10px;
  }
}

#wplaty {
  td {
    padding: 0 20px;
  }
  a.link {
    color: #000;
  }
}

.linia_w_formularzu {
  width: 100%;
  clear: both;
  .cztery, .dwa, .jeden, .trzy {
    display: inline-block;
    min-width: 200px;
    vertical-align: top;
  }
}

#dodawanie_firmy {
  .cztery input.text, .trzy input.text {
    width: 80%;
  }
  select {
    &.kategoria_glowna, &.podkategoria, &.podkategoria_sel {
      width: 80%;
    }
  }
  input.wider {
    width: 90%;
  }
}

#box_haslo .col1 {
  width: 40%;
}

#dodawanie_firmy input.kod_pocztowy {
  width: 120px;
}

#div_tagi span, .foto_wgrane {
  display: inline-block;
  border: 1px solid #ccc;
}

#div_tagi span {
  margin-right: 4px;
  padding: 2px 4px;
  margin-bottom: 4px;
}

.foto_wgrane {
  vertical-align: top;
  padding: 10px;
  margin-bottom: 10px;
}

#rwd_menu_bg {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

#rwd_menu_mobile {
  display: none;
  position: absolute;
  left: 20px;
  right: 20px;
  background-color: #fff;
  z-index: 10;
  text-align: left;
  line-height: 30px;
  li.dodaj_firme {
    background-color: #fdcf00;
  }
  a {
    color: #000;
    display: block;
  }
  li {
    margin: 6px 10px;
    padding: 4px 10px;
    background-image: url(../../css/gfx/rwd_menu.png);
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
  }
}

#mobile_button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: right;
  margin-top: 8px;
  list-style: outside none none;
  padding: 8px 8px 4px;
  cursor: pointer;
  display: none;
  margin-right: 10px;
  li {
    height: 2px;
    overflow: hidden;
    background-color: #000;
    margin-bottom: 4px;
    width: 21px;
  }
}

#quick_search_div {
  background-color: #fff;
  padding: 15px;
  position: absolute;
  display: none;
  box-sizing: border-box; 	
  li {
    cursor: pointer;
    color: #000;
    &.active {
      background-color: #ccc;
    }
  }
}

#box_lista_firm h2.kryteria {
  font-size: 12pt;
}

#zleceniodawca_mob {
  display: none;
  margin-bottom: 20px;
}

p.align_right {
  text-align: right;
}

ul.lista_firm p.align_right {
  margin-bottom: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#box_produkty ul.lista_firm p.opis_krotki {
  max-height: 60px;
}

#box_zlecenia .white_form_box input.text {
  width: 68%;
}

#filtruj_zlecenia ul.miasta {
  list-style: disc;
  margin-bottom: 20px;
}

#dodawanie_firmy select.dni {
  width: 20%;
}

#box_lista_zlecen div.half {
  display: inline-block;
  width: 80%;
  vertical-align: top;
  &.short {
    display: inline-block;
    width: 19%;
    text-align: center;
  }
}

#firma_opis {
  ul {
    list-style: disc;
    list-style-position: inherit;
    padding-left: 40px;
    margin: 16px 0;
  }
  p {
    padding: 5px 0;
  }
}

#dodaj_news_box h2.naglowek {
  font-size: 18pt;
}

#box_miasta h4 {
  font-weight: 400;
  display: inline;
}

#dodawanie_firmy .textarea_full {
  width: 100%;
}

#zlecenie_opis {
  ul {
    list-style: disc;
    padding-left: 40px;
    margin: 0;
  }
  ol {
    margin: 0;
  }
  p {
    margin-bottom: 10px;
  }
}

#div_prod_sort {
  float: right;
  display: inline-block;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #999;
  margin-top: 6px;
  select {
    margin-left: 5px;
    border: 0;
  }
}

.form_oferta input.text.telefon {
  width: 90px;
}

#godziny_pracy {
  label {
    clear: none;
    width: 140px;
  }
  span.godzina {
    width: 150px;
    height: 47px;
    line-height: 47px;
  }
  input.text {
    clear: none;
    margin: 0;
    float: none;
  }
  div.short {
    clear: both;
    margin-bottom: 10px;
  }
  span.godzina span.suwak {
    background-image: url(../../css/gfx/suwak.png);
    background-repeat: no-repeat;
    display: inline-block;
    height: 22px;
    margin-right: 10px;
    vertical-align: middle;
    width: 36px;
  }
}

#powiadomienia span.godzina span.suwak {
  background-image: url(../../css/gfx/suwak.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 22px;
  margin-right: 10px;
  vertical-align: middle;
  width: 36px;
}

#godziny_pracy span.godzina.on span.suwak, #powiadomienia span.godzina.on span.suwak {
  background-position: left bottom;
}

#godziny_pracy {
  label, span.godzina {
    display: inline-block;
    float: none;
  }
}

#powiadomienia span.godzina {
  display: inline-block;
  float: none;
}

.jcarousel-clip-horizontal {
  overflow: hidden;
}

.jcarousel-next {
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font: 24px/27px Arial,sans-serif;
  height: 75px;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 1px #000;
  top: 50%;
  width: 40px;
  background-image: url(../../gfx/css_sprites.png);
  background-repeat: no-repeat;
}

.jcarousel-prev {
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font: 24px/27px Arial,sans-serif;
  height: 75px;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 1px #000;
  top: 50%;
  width: 40px;
  background-image: url(../../gfx/css_sprites.png);
  background-repeat: no-repeat;
  left: -30px;
  background-position: -10px -589px;
}

.jcarousel-next {
  right: -30px;
  background-position: -10px -684px;
}

#cookiesBar {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  color: #414141;
  font: 400 11px/1em Arial,sans-serif;
  width: 100%;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: #ebebeb;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0%, #fff), color-stop(100%, #ebebeb));
  background-image: -webkit-linear-gradient(#fff, #ebebeb);
  background-image: -moz-linear-gradient(#fff, #ebebeb);
  background-image: -o-linear-gradient(#fff, #ebebeb);
  background-image: -ms-linear-gradient(#fff, #ebebeb);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff, endColorstr=#ebebeb);
  background-image: linear-gradient(#fff, #ebebeb);
  a {
    color: #414141;
    border-bottom: 1px dotted #aeaeae;
    &:hover {
      color: #aeaeae;
    }
  }
  p {
    margin: 2px 0;
    line-height: 1;
    padding-left: 30px;
  }
}

#cookiesBarWrap {
  width: 840px;
  margin: 0 auto;
  position: relative;
}

#cookiesBarClose {
  display: block;
  width: 20px;
  height: 19px;
  background: url(../../gfx/css_sprites.png) -67px -121px no-repeat;
  text-indent: -999em;
  position: absolute;
  top: 2px;
  left: 5px;
}

a#cookiesBarClose {
  border: 0;
}

#cookiesBarClose:hover {
  background-position: -97px -121px;
}


div.sposob { display: inline-block ; padding: 10px; width: 33% ; vertical-align: top; text-align: center; border: 1px solid #e6e6e6; box-sizing: border-box; }

.pakiet .opis p, .pakiet .opis ul {
	margin-bottom: 10px;
}

@media all and (max-width: 600px) {
 div.sposob { width: 100%; display: block; margin-bottom: 10px; }
}

@media screen and (max-width: 900px) {
  #cookiesBarWrap {
    margin: 0 auto;
    position: relative;
    width: 95%;
  }
}

@media screen and (max-width: 1050px) {
  #kod_qr {
    position: inherit;
  }
}

@media screen and (max-width: 1100px) {
  #partnerzy_div {
    width: 825px;
  }
}

@media screen and (max-width: 900px) {
  div.single_kat {
    width: 33%;
  }
  ul.lista_firm li.promo h3.nazwa {
    padding-right: 0;
  }
  div.latest_single {
    width: 25%;
  }
  ul.lista_firm div {
    &.foto {
      border-right: 0;
      float: none;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
    }
    &.dane {
      margin-left: 0;
    }
    &.kategorie {
      background-color: #f9f9f9;
      border-top: 1px solid #e6e6e6;
      color: #323232;
      padding: 11px;
      position: inherit;
    }
  }
  #box_zlecenie div {
    &.oferta div.col {
      text-align: center;
    }
    &.col_z_cena {
      br {
        display: none;
      }
      strong {
        margin-right: 10px;
      }
    }
    &.col {
      width: 100%;
      display: block;
    }
  }
  p.oferta_txt span.data {
    width: 100%;
    display: block;
  }
  #box_zlecenie .w_linii h1 {
    display: block;
    font-size: 20pt;
  }
  #zlecenie_main {
    margin-right: 0;
  }
}

#moje_firmy .guzik_long {
  padding: 8px 40px; 
}

@media screen and (max-width: 825px) {
  #partnerzy_div {
    width: 550px;
  }
  #mobile_button {
    display: block;
  }
  #moje_firmy p.uzupelnij, #rwd_menu {
    display: none;
  }
  #nowa_opinia div.col {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .grid ul.lista_firm li, div.single_kat {
    width: 50%;
  }
  #form_szukaj {
    padding-top: 70px;
    .sl-container {
      margin-bottom: 10px;
    }
  }
  div {
    &.latest_single, &.single_news {
      width: 33%;
      margin-bottom: 10px;
    }
    &.opinia img.thumb {
      float: none;
      margin: 0;
    }
    &.single_news {
      width: 50%;
    }
  }
  #box_aktualnosci_main div.col, #box_firma div.col, #box_firmy div.col, #box_produkty div.col {
    display: block;
  }
  #box_lista_firm, #plugin_aktualnosci_lista {
    margin-right: 0;
  }
  #sidebar {
    width: 100%;
  }
  #box_lista_zlecen {
    margin-right: 0;
  }
  #box_haslo {
    .col {
      display: block;
      width: 100%;
    }
    .col1 {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  #box_lista_zlecen .w_linii {
    height: auto;
    h1 {
      font-size: 20pt;
    }
  }
}

@media screen and (max-width: 670px) {
  #form_szukaj h1 {
    font-size: 42pt;
  }
  #dodawanie_firmy div.kolumna {
    float: none;
	 width: 100%;	
  }	
  #box_wplaty thead tr, #moje_firmy div.half.half_short br, #top_1 p.italic {
    display: none;
  }
  #mapa_miast > div.woj {
    width: 50%;
  }
  #map-poland {
    float: none;
  }
  #box_statystyki li {
    text-align: center;
    margin-bottom: 10px;
  }
  #box_stopka_linki div.col {
    display: inline-block;
    padding: 0 20px;
  }
  #moje_firmy div {
    &.foto {
      display: block;
      float: none;
      width: 100%;
    }
    &.half {
      display: block;
      float: none;
      width: 100%;
      &.half_short {
        display: block;
        float: none;
        width: 100%;
        p {
          padding-top: 20px;
        }
      }
    }
  }
  #layer {
    box-sizing: padding-box;
  }
  #layer_in {
    width: 80%;
    height: auto !important;
    > img {
      width: 100%;
    }
  }
  #moje_firmy {
    div.foto {
      text-align: center;
    }
    img.thumb {
      display: inline-block;
    }
    div.stat {
      position: inherit;
      padding: 0 15px 15px;
    }
  }
  #box_wplaty {
    table, td, th, thead, tr {
      display: block;
    }
  }
  #box_wplatytbody {
    display: block;
  }
  #box_statystyki li {
    width: 100%;
    display: block;
  }
  #box_wplaty {
    tbody {
      width: 100%;
      display: block;
    }
    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }
    td {
      border: 0;
      border-bottom: 1px solid #eee;
      position: relative;
      text-align: center;
      &:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 1px;
        padding-right: 10px;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  #partnerzy_div {
    width: 275px;
  }
  .glowna_woj li {
    width: 33%;
  }
  #avatec_faceplusbox, .mobile_off {
    display: none;
  }
  .mobile_on, span.mbr {
    display: block;
  }
  #logo img {
    max-width: 230px;
  }
  span.mbr {
    clear: both;
  }
  div.pakiet {
    padding-top: 10px;
    img.typ {
      margin: auto;
      display: block;
      float: none;
    }
    .opis p {
      padding: 0 10px 10px;
    }
    span.nazwa {
      display: inline-block;
    }
  }
  #plugin_aktualnosci_lista {
    img.thumb {
      width: 100%;
      float: none;
      margin: 0;
      height: auto;
    }
    div.kategoria {
      position: relative;
      left: 0;
    }
  }
  #box_lista_zlecen {
    .w_linii a {
      float: none;
      margin-left: 20px;
    }
    div.half {
      display: block;
      width: 100%;
      &.short {
        display: block;
        width: 100%;
      }
    }
  }
  #box_newsletter input.text, #box_produkty_szukaj input.text {
    width: 60%;
  }
  #mapa_miast > div.woj {
    display: inline-block;
    width: 100%;
  }
  #box_newsletter button.submit, #box_produkty_szukaj button.submit {
    padding: 15px 0;
    width: 38%;
  }
  #box_firma #sidebar, #box_firma_produkty .latest_single, #box_firmy_pokrewne .latest_single, #dodawanie_firmy select.dni {
    width: 100%;
  }
  #firma_div {
    margin-right: 0;
  }
  .foto_firmy, div.pakiet span.nazwa {
    width: 100%;
    text-align: center;
  }
  #firma_div img.foto {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 3px;
    border-right: 0;
  }
  #kod_qr {
    position: inherit;
  }
  #big_f {
    width: 90%;
    margin: auto;
  }
  #big_foto {
    text-align: center;
  }
  .alert-cloud {
    clear: both;
  }
  #produkt_div {
    margin-right: 0;
  }
  #box_produkt {
    div.col {
      display: block;
      width: 100%;
    }
    #sidebar {
      width: 100%;
      margin-top: 20px;
    }
  }
  #dodawanie_firmy {
    input.text, select, textarea {
      float: none;
      width: 100%;
    }
  }
  #map_canvas, .alert-cloud {
    float: none;
    width: 100%;
  }
  #dodawanie_firmy input.text.short {
    width: 80%;
  }
  span.budzet {
    float: none;
  }
  #map_canvas {
    clear: left;
    height: 300px;
  }
  #dodawanie_firmy {
    .linia_w_formularzu {
      .cztery, .dwa, .jeden, .trzy {
        display: block;
        min-width: 100%;
        vertical-align: top;
      }
    }
    .cztery input.text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  div.latest_single img {
    display: none;
  }
  .body_akcja {
    #top_3 {
      height: auto;
    }
  }
  .grid ul.lista_firm li, div.single_kat {
    width: 100%;
  }
  #zlecenie_info p {
    display: block;
    width: 100%;
  }
  #zleceniodawca_mob {
    display: block;
  }
  #box_miasta, #box_tagi, #zleceniodawca {
    display: none;
  }
  #form_szukaj {
    padding-top: 40px;
    div.clear {
      margin-top: 20px;
    }
  }
  div.latest_single {
    width: 50%;
  }
  #box_opinie div.col {
    display: block;
  }
  div.opinia img.thumb {
    float: left;
    margin-right: 10px;
  }
  #box_opinie div.col, div.single_news {
    margin-bottom: 10px;
    width: 100%;
  }
  #footer_bottom div.col {
    display: block;
    width: 100%;
    text-align: center;
    vertical-align: top;
  }
  #produkt_galeria {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  div.col {
    display: block;
  }
  #box_lista_firm, #box_lista_zlecen {
    margin-right: 0;
  }
  #box_logowanie div.col {
    display: block;
    margin-bottom: 10px;
  }
  #add-favourites, #schowek {
    display: none;
  }
  #box_firma h1 {
    margin-top: 0;
  }
}

@media screen and (max-width: 460px) {
  div.opinia_oceny {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    table {
      width: 100%;
    }
  }
  #div_prod_sort {
    background-color: #fff;
    border: 1px solid #999;
    display: inline-block;
    float: none;
    margin-top: 6px;
    padding: 3px;
  }
}

@media screen and (max-width: 400px) {
  #nowa_opinia .opinia_prawa {
    input.text, textarea {
      width: 100%;
    }
  }
  div.latest_single {
    width: 100%;
  }
  .glowna_woj li {
    width: 50%;
  }
  #form_szukaj {
    .sl-container, input {
      width: 80%;
      margin: auto auto 10px;
      padding: 0 15px;
      box-sizing: content-box;
    }
    .sl-container {
      text-indent: 0;
    }
    .transparent_button, div.clear {
      margin-top: 5px;
    }
    h1 {
      font-size: 33pt;
    }
  }
  #dodaj_opinie {
    position: inherit;
    margin-bottom: 10px;
  }
  #opinie_pl div.linia {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  #box_nowa_opinia div {
    &.polowa2 label {
      width: 120px;
    }
    &.pelny {
      text-align: center;
      label {
        text-align: center;
      }
    }
    &.polowa2 {
      text-align: center;
    }
  }
  #nowa_opinia .opinia_prawa {
    div.pelny label, label {
      float: none;
      width: 100%;
      margin: 0;
      text-align: left;
    }
  }
}