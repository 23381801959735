.company {
  @media (min-width: $lg) {
    padding-top: 3rem;
  }

  .container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 30px;

    @media (min-width: $lg) {
      grid-template-columns: minmax(0, 1fr) 330px;
    }
  }

  main {
    padding-top: 2rem;
  }

  #add-favourites {
    font-size: 1.4rem;
    color: #323232;
    display: inline;
  }
}

.company__section {
  padding: 3.5rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border-color;
  }
}

.company__section-title,
.related__title {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.company__attributes {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 10px 30px;

  @media (min-width: $lg) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }

  li {
    padding-left: 2rem;
    background: url('../images/icon-check.png') 0 7px no-repeat;
  }
}

.company__extra {
  font-family: $font-secondary;
  font-size: 1.6rem;
  font-weight: 700;

  li {
    margin-bottom: 1rem;
  }
}

.company__section.entry-text {
  img {
    max-width: 100%;
    height: auto;
  }
}

.company-header {
  margin-bottom: 2.5rem;

  h1 {
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 3rem;

    @media (min-width: $md) {
      font-size: 3.6rem;
    }
  }

  p {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  #firma_ocena {
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid $border-color;
  }
}

.related {
  margin-top: 5rem;
  display: none;

  @media (min-width: $lg) {
    display: block;
  }
}

.company__gallery {
  background-color: #f4f4f4;
  position: relative;

  .slick-slide img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .slick-arrow {
    width: 32px;
    height: 61px;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    z-index: 20;

    &.slick-next {
      right: 10px;
      background: url('../images/arrow-right.png') 50% 50% no-repeat;

      @media (min-width: $md) {
        right: 50px;
      }
    }

    &.slick-prev {
      left: 10px;
      background: url('../images/arrow-left.png') 50% 50% no-repeat;

      @media (min-width: $md) {
        left: 50px;
      }
    }
  }
}

#polec_boks {
  font-size: 1.3rem;
  margin-bottom: 2.5rem;

  @media (min-width: $lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span.icon {
    background-image: url(../images/icon-fb.png);
    width: 51px;
    height: 51px;
    vertical-align: middle;
    margin-right: 10px;
    margin-top: 1rem;

    @media (min-width: $lg) {
      margin-left: 10px;
      margin-right: 0;
      margin-top: 0;
    }
  }

  #polec_google {
    background-image: url(../images/icon-gp.png);
  }

  #polec_twitter {
    background-image: url(../images/icon-tw.png);
  }
}

#polec_boks span.icon, #remove-favourites-item {
  display: inline-block;
  cursor: pointer;
}

.company__contact {
  background-color: $gray;
  color: #fff;
  padding: 2rem;
  margin-bottom: 2.5rem;
  display: none;

  @media (min-width: $md) {
    display: block !important;
  }

  h4 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    background-color: #fff;
    border-radius: 0;
    width: 100%;
    height: 42px;
    padding: 0.3rem 2rem;
    color: $text-color;
  }

  .form-control-textarea {
    background-color: #fff;
    color: $dark;
    padding: 1rem 2rem;
  }

  .submit-group {
    padding: 1.5rem 0;

    .btn {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  p {
    color: #7e7e7e;
    font-size: 1.2rem;

    a {
      color: #fff;
    }
  }
}

.company__location {
  background: $light url("../images/icon-marker.png") 20px 25px no-repeat;
  padding: 2rem 2rem 2rem 4rem;
  border: 1px solid $border-color;
  line-height: 2rem;

  .map-link {
    color: $primary;
  }
}

.company__contacts {
  background-color: $light;
  border: 1px solid $border-color;
  padding: 1rem 2rem;

  .btn {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    width: 125px;
  }

  div {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    min-height: 46px;

    span {
      display: flex;
      align-items: center;

      a {
        margin-left: 0.3rem;
      }
    }
  }

  a {
    color: $primary;
    display: inline-block;
  }

  .dots {
    display: inline-block;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
}

.toggle-contact {
  @extend .toggle-search;

  margin: 1rem 0;
}

.company__gallery + aside {
  margin-top: 2rem;
}
