.home-search {
  padding: 9.2rem 0 0;
  text-align: center;
  color: #fff;

  @media (min-width: $md) {
    padding: 15rem 1.5rem 4rem;
    background: $dark url("../images/intro.jpg") 0 0 no-repeat;
    background-size: cover;
  }

  @media (min-width: $lg) {
    padding: 28rem 1.5rem 14rem;
  }

  @media (max-width: $md) {
    hgroup {
      background: $dark url("../images/intro.jpg") 0 0 no-repeat;
      background-size: cover;
      padding: 6rem 0;

      h2 {
        margin-bottom: 0;
      }
    }
  }

  select {
    text-align: left;
  }

  h1 {
    font-size: 4.8rem;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1;

    @media (min-width: $md) {
      font-size: 11.5rem;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 2rem;

    @media (min-width: $md) {
      font-size: 2.4rem;
    }
  }
}

.home-search__inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  max-width: 900px;
  background: rgba($dark, 0.8);
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    grid-template-columns: 2fr 2fr;
    grid-gap: 20px;
  }

  @media (min-width: $lg) {
    grid-template-columns: 3fr 3fr 3fr 2fr;
    grid-gap: 30px;
  }
}

.search-pro__controls {
  padding: 1rem;
  background-color: rgba($dark, 0.8);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media (min-width: $sm) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  input {
    background-color: #fff;
    height: 38px;
    border-radius: 0.3rem;
    width: 100%;
    padding: 0 1rem;
  }
}

.search-pro__checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 30px;
  padding: 2rem 0;

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  label {
    display: grid;
    grid-template-columns: 20px 1fr;

    input {
      position: relative;
      top: 3px;
    }
  }
}

.search-pro__bottom {
  text-align: center;
  padding-bottom: 1rem;
}

.toggle-search {
  display: block !important;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  background: url('../images/arrow-down-white.png') calc(100% - 2rem) 50% no-repeat;

  @media (min-width: $md) {
    display: none !important;
  }
}

.search-pro {
  display: none;

  @media (min-width: $md) {
    display: block !important;
  }
}

.search-more-criteria {
  text-align: right;
  padding-top: 1rem;
  display: none;

  span {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.4rem;

    &:hover {
      color: $text-color;
    }
  }

  @media (min-width: $md) {
    display: block;
  }
}

@media (min-width: $md) {
  .search-pro__checks {
    display: none;
  }

  .search-pro__checks.open {
    display: grid;
  }
}