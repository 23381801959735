html {
  font-size: 62.5%;
}

body {
  font-family: $font-primary;
  font-size: $base-font-size;
  color: $text-color;

  &:not(.home) {
    padding-top: 14rem;
  }
}

.container,
.wrapper {
  width: 100%;
  padding: 0 1.3rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    width: 720px;
  }

  @media (min-width: $lg) {
    width: 1100px;
  }

  @media (min-width: $xl) {
    width: 1430px;
  }
}

.header {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.4rem !important;
}

.separator {
  margin: 3rem 0;
  background-color: transparent;
  border: 0;
  border-top: 1px solid $border-color;
}

#add-favourites {
  cursor: pointer;
}

.entry-text {
  ul, ol {
    padding-left: 2rem;
    list-style: disc;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ol {
    list-style: decimal;
  }
}

@media (max-width: $lg) {
  #avatec_faceplusbox {
    display: none !important;
  }
}