.main-header {
  background: rgba($dark, 0.8);
  padding: 3.5rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  .container {
    position: relative;

    @media (min-width: $lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .menu-toggle {
    display: block;
    width: 58px;
    height: 50px;
    background-color: $primary;
    position: absolute;
    right: 1.3rem;
    top: -1.6rem;
    border-radius: 0.3rem;
    cursor: pointer;

    span {
      position: absolute;
      width: 32px;
      height: 2px;
      background-color: #fff;
      left: 50%;
      margin-left: -16px;
      top: 1.7rem;

      &:nth-child(2) {
        top: 2.4rem;
      }

      &:nth-child(3) {
        top: 3.1rem;
      }
    }

    @media (min-width: $lg) {
      display: none;
    }
  }
}

.user-panel {
  display: none;

  @media (min-width: $lg) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $lg) {
    .btn {
      margin-left: 2rem;
    }
  }

  .btn img {
    margin-right: 0.5rem;
    margin-left: -0.8rem;
  }

  @media (max-width: $lg) {
    #jezyki {
      margin-top: 1rem;

      ul {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  ul {
    @media (min-width: $lg) {
      display: flex;
    }
    @media (max-width: $lg) {
      padding: 4rem 0 1rem;
    }

    li {
      @media (min-width: $lg) {
        margin-left: 2rem;
      }
    }

    a {
      color: #fff;
      font-size: 1.8rem;

      @media (max-width: $lg) {
        display: block;
        padding: 1rem 0;
      }
    }
  }
}