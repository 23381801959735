.bundle {
  .header {
    text-align: center;
    margin-bottom: 3rem;
  }
}

.bundle__intro {
  margin-top: -2.5rem;
  background-color: #f7f7f7;
  text-align: center;
  padding: 5rem 0;
  margin-bottom: 4rem;
}

.bundle__options {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 30px;

  @media (min-width: $lg) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

.bundle__item {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 16rem;
  position: relative;
  border: 1px solid $border-color;

  form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f7f7;
    padding: 2rem;
  }

  label {
    display: block;
    font-family: $font-secondary;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  select {
    margin-bottom: 2rem;
  }
}

.bundle__name {
  font-family: $font-secondary;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bundle__description {
  text-align: left;
  margin-bottom: 2rem;
  padding: 2rem;

  ul {
    padding-left: 2rem;
    list-style: disc;
  }
}

.bundle_goto {
  text-align: center;
  margin-top: 4rem;
}