#rodo {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  z-index: 5000;
}

#rodo_in {
  background-color: #fff;
  padding: 10px;
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-block;
  width: 99%;
}

#rodo_txt {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}

#rodo_ok {
  color: #fff;
  background-color: $primary;
  padding: 10px 20px;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}