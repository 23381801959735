/*

Source Sans Pro
extra-light 200
extra-light 200 Italic
light 300
light 300 Italic
regular 400
regular 400 Italic
semi-bold 600
semi-bold 600 Italic
bold 700
bold 700 Italic
black 900
black 900 Italic

Raleway
thin 100
thin 100 Italic
extra-light 200
extra-light 200 Italic
light 300
light 300 Italic
regular 400
regular 400 Italic
medium 500
medium 500 Italic
semi-bold 600
semi-bold 600 Italic
bold 700
bold 700 Italic
extra-bold 800
extra-bold 800 Italic
black 900
black 900 Italic

*/

$font-secondary: 'Raleway', sans-serif;
$font-primary: 'Source Sans Pro', sans-serif;

$base-font-size: 1.6rem;

/* Colors */

$orange: #ff7e00;
$dark: #060505;
$gray: #383737;
$gray-light: #b5b5b5;
$light: #f3f3f3;

/* Theme */

$primary: $orange;
$text-color: $dark;
$border-color: $gray-light;

/* Breakpoints */

$sm: 576px;
$md: 768px;
$lg: 1200px;
$xl: 1500px;