.home-categories {
  margin-top: 7.5rem;
  margin-bottom: 9rem;

  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 35px 25px;

    @media (min-width: $md) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: $xl) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    li {
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  a {
    display: block;
    border: 1px solid $border-color;
    padding: 1rem;
    height: 100%;
  }

  a:hover {
    text-decoration: none;
    box-shadow: 0 0 10px rgba(#000, 0.1);
    border-color: $primary;

    div {
      opacity: 0.8;
    }
  }
}

.home-categories__name {
  font-family: $font-secondary;
  font-weight: 900;
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.home-categories__data {
  padding: 2rem;
}