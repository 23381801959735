.btn {
  font-size: 1.8rem;
  border-radius: 0.3rem;
  display: inline-block;
  padding: 1.3rem 3rem;

  &:hover {
    text-decoration: none;
  }
}

.btn--primary {
  background-color: $primary;
  color: #fff;

  &:hover {
    background-color: mix(#fff, $primary, 10%);
    box-shadow: inset 0 0 0 2px $primary;
  }
}

.btn--secondary {
  background-color: $gray;
  color: #fff;

  &:hover {
    background-color: mix(#fff, $gray, 10%);
    box-shadow: inset 0 0 0 2px $gray;
  }
}