%items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 35px 25px;
  margin-bottom: 7rem;

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  a {
    display: block;
    border: 1px solid $border-color;
    padding: 1rem;
    height: 100%;
    position: relative;

    & > span {
      background-color: $primary;
      position: absolute;
      left: 3rem;
      top: 3rem;
      font-size: 1.8rem;
      color: #fff;
      padding: 0.3rem 2rem;
      border-radius: 0.3rem;
    }

    div {
      padding: 0.5rem 1rem;
    }
  }

  a:hover {
    text-decoration: none;
    box-shadow: 0 0 10px rgba(#000, 0.1);
    border-color: $primary;

    div {
      opacity: 0.8;
    }
  }
}

.listing {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (min-width: $lg) {
    grid-template-columns: 1fr 330px;
  }

  .sticky {
    position: sticky;
    top: 30px;
  }

  .items {
    @extend %items;
  }

  .items .promo a {
    border-width: 3px;
    border-color: $primary;
  }

  main aside #mapa {
    height: 400px !important;
  }

  main aside {
    display: none;
  }
}

.items--related {
  @extend %items;

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.items__title {
  font-family: $font-secondary;
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;

  & + span {
    font-size: 1.3rem;
  }
}

.toggle-map {
  display: block !important;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  background-image: url('../images/arrow-down-white.png');
  background-position: calc(100% - 2rem) 50%;
  background-repeat: no-repeat;

  @media (min-width: $md) {
    display: none !important;
  }
}