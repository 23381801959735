.main-footer {
  margin-top: 8rem;
  background-color: $dark;
  color: #fff;
  padding: 3rem 0 6rem;
  font-size: 1.3rem;

  p {
    line-height: 1.7;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media (min-width: $lg) {
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 35px 100px;
    }

    @media (max-width: $md) {
      text-align: center;

      .main-footer__links {
        padding: 0;
        list-style: none;
      }

      .main-footer__header {
        display: none;
      }

      .main-footer__socials {
        margin-left: auto;
        margin-right: auto;
      }

      .main-footer__header + p {
        display: none;
      }
    }
  }
}

.main-footer__header {
  font-family: $font-secondary;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
}

.main-footer__links {
  list-style: disc;
  padding-left: 2rem;

  li {
    margin-bottom: 0.4rem;
  }
}

.main-footer__socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  margin-top: 2rem;

  a:hover {
    opacity: 0.7;
  }
}

.main-footer__contact {
  a {
    font-size: 2.3rem;
    color: $primary;
  }
}