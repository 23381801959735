.cta {
  text-align: center;
  margin-bottom: 2rem;
}

.cta__text {
  padding: 2rem 0 3rem;
  line-height: 1.8;
}

.cta__header {
  font-family: $font-secondary;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 3rem;
}